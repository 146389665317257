button.round{
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid transparent;
}

button.round:hover{
    box-shadow: none;
    cursor: pointer;
}

button.round.close:hover{
    border: 2px solid var(--red);
}

button.round.newsletter{
    border: 2px solid var(--blue);
}

button.round.contact{
    border: 2px solid var(--blue);
}

button.close i{
    font-weight: bold;
    font-size: xx-large;
}

button.close i{
    color: var(--red);
}

section.button-container{
    margin: 0px auto 5px auto;
    width: fit-content;
    height: fit-content;
}

section.button-container.newsletter{
    background-color: transparent;
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    border-color: var(--blue);
}

section.button-container.contact{
    background-color: transparent;
    position: fixed;
    right: 20px;
    bottom: 90px;
    border-radius: 50%;
    border-color: var(--blue);
}

section.button-container.newsletter i{
    color: var(--blue);
}

section.button-container.contact i{
    color: var(--blue);
}