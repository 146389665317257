*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: none;
  list-style-type: none;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  outline: none;
  box-sizing: unset;
  box-decoration-break: unset;  
  border: none;
  border-color: none;
  /* pointer-events: none; */
}

:root{
  --gray : #282c34;
  --red: #ff0008;
  --white: #fff;
  --black: #000000d6;
  --blue: #12a1c8;
  --green: rgb(47, 248, 47);

}

body{
  width: 100dvw;
  height: 100dvh;
  display: flex;
}

header, main, footer,
aside, section, nav{
  display: flex;
  flex-direction: row;
  width: 100dvw;
}

section{
  background-color: var(--gray);
  color: var(--white);
}

h1, h2, h3,
h4, h5, h6{
  text-transform: uppercase;
}

main{
  flex-direction: column;
}

.App, #root{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
