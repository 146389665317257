section.about{
    background-color: var(--gray);
    color: var(--white);
    padding: 10px 0px;
}

.name-text{
    stroke-width: 0.1rem;
    stroke: var(--white);
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    font-size: 3rem;
    fill: transparent;
}

.name-text.animate{
    animation: name-animation 4s ease-in-out .2s 1 alternate both;
}

.name, .job{
    text-transform: uppercase;
    font-weight: bold;
    font-size: x-large;
    text-align: center;
    justify-content: center;
    display: flex;
}

.summary p{
    padding: 20px 0px;
}

.about-me{
    width: clamp(200px, 70dvw, 100dvw);
}

.picture-me{
    display: block;
    position: relative;
    width: clamp(200px, 30dvw, 100dvw);
}

figure{
    width: 100%;
    height: 100%;
}

figure img {
    border-radius: 50%;
    width: clamp(100px, 350px, 90%);
    height: clamp(100px, 350px, 90%);
}

p{
    color: var(--white);
}

@keyframes name-animation {
    from {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
    } 

    90%{
        fill: var(--white);
    }

    to {
        stroke-dasharray: 275;
        stroke-dashoffset: 0;
        fill: var(--white);
    }
}

/* @media (min-width : 768px) and (max-width : 991px){ */
@media screen and (max-width : 991px){

}


/* @media (min-width : 576px) and (max-width : 767px){ */
@media screen and (max-width : 767px){
     /* .name-text{
        font-size: 3rem;
    } */

    section.about{
        flex-direction: column;
    }
    
    .picture-me, .about-me{
        width: 100dvw;
    }
    
    .picture-me{
        order: 1;
    }

    .about-me{
        order: 2;
    }
}

@media screen and (width <= 576px) {

}