section.skills{
    padding: 20px 0px;
}

.skills-container{
    padding: 0px 20px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px 30px;
    align-items: center;
}