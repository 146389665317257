.ieval-container{
    width: calc(100% - 10px);
    font-weight: bold;
    display: flex;
    text-align: start;
    vertical-align: middle;
}

.ieval{
    width: 100%;
}

.ieval i{
    padding: 0px 5px;
    font-size: 1em;
}

.ieval-container{
    padding: 10px 0px 10px 10px;
    border: 2px solid transparent;
    border-radius: 10px;
}

.ieval-container.success, .ieval.success{
    border-color: var(--green);
    color: var(--green);
}

.ieval-container.failure, .ieval.failure{
    border-color: var(--red);
    color: var(--red);
}

