footer{
    padding: 20px 0px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: auto;
    background-color: var(--black);
    color: var(--white);
}

footer p{
    text-transform: uppercase;
    font-weight: bold;
}
