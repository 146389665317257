.social-container{
    position: absolute;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
}

.social-container img{
    height: 60px;
    width: 60px;
}

.social-container:nth-child(1){
    top: 5%;
    left: 15%;
}

.social-container:nth-child(2){
    top: 5%;
    right: 15%;
}

.social-container:nth-child(3){
    top: 30%;
    right: 5%;
}

.social-container:nth-child(4){
    top: 60%;
    right: 15%;
}


.social-container:nth-child(5){
    top: 60%;
    left: 15%;
}

.social-container:nth-child(6){
    top: 30%;
    left: 5%;
} 

.social-container.animate{
    animation: socialAnimate .4s ease-in-out 1s infinite alternate both;
}

@keyframes socialAnimate {
    from {
        transform: scale(1);
    }

    to{
        transform: scale(1.2);
    }
}



@media screen and (max-width : 767px) {
    .social-container:nth-child(1){
        top: 5%;
        left: 25%;
    }
    
    .social-container:nth-child(2){
        top: 5%;
        right: 25%;
    }
    
    .social-container:nth-child(3){
        top: 35%;
        right: 20%;
    }
    
    .social-container:nth-child(4){
        top: 65%;
        right: 25%;
    }
    
    
    .social-container:nth-child(5){
        top: 65%;
        left: 25%;
    }
    
    .social-container:nth-child(6){
        top: 35%;
        left: 20%;
    } 
}
  
@media screen and (min-width : 767px) and (max-width : 1023px){
    .social-container:nth-child(1){
        top: 5%;
        left: 15%;
    }
    
    .social-container:nth-child(2){
        top: 5%;
        right: 15%;
    }
    
    .social-container:nth-child(3){
        top: 30%;
        right: 5%;
    }
    
    .social-container:nth-child(4){
        top: 60%;
        right: 15%;
    }
    
    
    .social-container:nth-child(5){
        top: 60%;
        left: 15%;
    }
    
    .social-container:nth-child(6){
        top: 30%;
        left: 5%;
    } 
}
  
@media screen and (max-width : 500px){

    .social-container img{
        height: 48px;
        width: 48px;
    }

    .social-container:nth-child(1){
        top: 5%;
        left: 15%;
    }
    
    .social-container:nth-child(2){
        top: 5%;
        right: 15%;
    }
    
    .social-container:nth-child(3){
        top: 35%;
        right: 0%;
    }
    
    .social-container:nth-child(4){
        top: 70%;
        right: 15%;
    }
    
    
    .social-container:nth-child(5){
        top: 70%;
        left: 15%;
    }
    
    .social-container:nth-child(6){
        top: 35%;
        left: 0%;
    } 
} 