header{
    height: 150px;
    background-color: var(--black);
    position: sticky;
}

.logo-container{
    display: flex;
    flex-direction: row;
    width: clamp(200px, 50%, 300px);
}

img{
    width: clamp(100px, 100, 100px);
}

.download-container{
    position: absolute;
    align-self: center;
    right: 30px;
    width: clamp(200px, 50%, auto);
}

.download-container a{
    padding: 20px 10px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: var(--red);
    color: var(--white);
    border: 1px dashed var(--gray);
}

.download-container a:hover{
    color: var(--red);
    background-color: var(--white);
    border: 1px dashed var(--red);
}

i.pi-download{
    color: var(--white);
}

.download-container a:hover i.pi-download{
    color: var(--red);
}

@media screen and (max-width : 767px) {
    
}

@media screen and (min-width : 767px) and (max-width : 1023px){
    
}

@media screen and (max-width : 500px) {
    .logo-container{
        width: 50%;
    }

    img{
        width: 100%;
    }

    .download-container{
        right: 10px;
        width: clamp(50%, 50%, auto);
    }

    .download-container a{
        padding: 15px;
        font-size: 0.7rem;
    }
}