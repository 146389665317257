.trad-container {
    padding: 5px 0px;
    display: flex;
    flex-direction: column;
    width: 60px;
    position: fixed;
    bottom: 20px;
    left: 0px;
    align-items: center;
    vertical-align: middle;
    align-content: center;
    z-index: 999;
}

.trad-container figure, .trad-container img{
    width: 20px;
    height: 20px;
}

.trad-container figure:hover{
    cursor: pointer;
}