.input-field-container{
  padding: 10px 0px;
  width: 100%;
}

.input-field-container.hidden, .input-field.hidden, input[type='hidden']{
  inset: 0px;
  margin: 0px;
  padding: 0px;
  visibility: hidden;
  opacity: 0;
  height: 0px;
}

.input-field{
  margin: 10px auto;
  text-align: start;
  width: 80%;
}

input{
  width: 100%;
  height: 60px;
  font-size: 1.6rem;
  caret-color: var(--blue);
  background-color: var(--gray);
  color: var(--white);
  border-bottom: 2px solid var(--white);
}

input:focus{
  border-bottom: 2px solid var(--blue);
}

input.empty{
  border-bottom: 2px solid var(--gray);
}
  
input.valid{
  border-bottom: 2px solid var(--green);
}

input.invalid {
  border-bottom: 2px solid var(--red);
}

label{
  position: relative;
  padding: 0px 5px;
  left: 10px;
  top: -40px;
  color: var(--white);
  font-size: 1.6rem;
  font-weight: bold;
}

input:focus ~ label, 
input.valid ~ label, 
input.invalid ~ label{
  /* margin: 10px 0px; */
  left: 5px;
  top: -70px;
  background-color: var(--gray);
  font-size: 1rem;
  transition: all .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

button.btn-reset{
  opacity: 0;
  visibility: hidden;
  display: none;
}

@media screen and (max-width : 767px) {
  label, input{
    font-size: 1.2rem;
  }
  
  input{
    height: 40px;
  }
}

@media screen and (min-width : 767px) and (max-width : 1023px){
    
}

@media screen and (max-width : 500px){
  
}