nav{
    height: 60px;
}

.link{
    width: 33.33dvw;
}

nav a{
    padding: 20px 10px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: var(--red);
    color: var(--white);
    border: 1px solid var(--gray);
}

nav a:hover, nav a.active{
    color: var(--red);
    background-color: var(--white);
    border: 1px dashed var(--red);
}

@media screen and (max-width : 767px) {
    nav a{
        font-size: .8rem;
    }
    
}

@media screen and (min-width : 767px) and (max-width : 1023px){
    
}

@media screen and (max-width : 500px){
    
}