.textarea-field-container{
  padding: 10px 0px;
  width: 100%;
}

.textarea-field{
  margin: 10px auto;
  text-align: start;
  width: 80%;
}

textarea{
  width: 100%;
  min-height: 60px;
  font-size: small;
  caret-color: var(--blue);
  background-color: var(--gray);
  color: var(--white);
  border-bottom: 2px solid var(--white);
}
  
textarea:focus{
  border-bottom: 2px solid var(--blue);
}

textarea.empty{
  border-bottom: 2px solid var(--gray);
}
  
textarea.valid{
  border-bottom: 2px solid var(--green);
}

textarea.invalid {
  border-bottom: 2px solid var(--red);
}

textarea ~ label{
  position: relative;
  padding: 0px 5px;
  left: 10px;
  top: -50px;
  color: var(--white);
  font-size: 1.6rem;
  font-weight: bold;
}

textarea:focus ~ label, 
textarea.valid ~ label, 
textarea.invalid ~ label{
  /* margin: 10px 0px; */
  left: 5px;
  top: -90px;
  background-color: var(--gray);
  font-size: 1rem;
  transition: all .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

button.btn-reset{
  opacity: 0;
  visibility: hidden;
  display: none;
}


@media screen and (max-width : 767px) {
  label, textarea{
    font-size: 1.2rem;
  }
  
  textarea{
    min-height: 40px;
  }
}

@media screen and (min-width : 767px) and (max-width : 1023px){
    
}

@media screen and (max-width : 500px){
  
}