.card-container {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 150px;  
    background-color: var(--black);
    border-radius: 0px 30px 30px 30px;
}

.card-container:hover{
    cursor: pointer;
}

.card-up, .card-down{
    width: 100%;
    display: flex;
    align-items: center;
}

.card-down{
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.card-up .card-title{
    min-width: 70%;
}

.card-up .card-title, .card-down .card-content{
    font-weight: bold;
    text-transform: uppercase;
}

.card-down .card-content{
    display: flex;
    width: 100%;
}

.card-up .card-title{
    display: flex;
    width: 100px;
    height: 100px;
    align-items: center;
}

.card-up .card-img{
    width: 80px;
    height: 80px;
}

.card-up .card-img figure, .card-up .card-img img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width : 767px) {
    .card-container {
        /* padding: 10px 20px; */
        width: 35dvw;
        height: 150px;  
    }
    
}

@media screen and (min-width : 767px) and (max-width : 1023px){
    .card-container {
        /* padding: 10px 20px; */
        width: 24dvw;
        height: 150px;  
    }
    
}

@media screen and (max-width : 500px){
    .card-container {
        /* padding: 10px 20px; */
        width: 80dvw;
        height: 150px;  
    }
    
}