section.scholarship-container{
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    background-color: var(--gray);
    color: var(--white);
}

section.scholarship-container .degree-container::before{
    content: '.';
    font-weight: bolder;
    font-size: 60px;
    position: relative;
    display: block;
    float: left;
    left: -19px;
    top: -50px;
}

section.scholarship-container .degree-container{
    padding: 0px 0px 0px 11px;
    border-left: 2px solid var(--white);
    width: 90dvw;
    align-self: flex-end;
    text-align: left;
}