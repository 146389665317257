.button-container{
    margin: 0px auto;
    width: 80%;
}

button{
    margin: 0px auto;
    padding: 10px;
    height: 30px;
    text-align: center;
    background-color: var(--blue);
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
    border-radius: 30px 0px 30px 0px;
}

button:hover{
    cursor: progress;
    backdrop-filter: blur(10px);
    box-shadow: 3px 3px 3px #fff;
    transform: scale(1.2);
}