section.scholarship-container .list-container{
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 10px;
}

.list-container:hover{
    cursor: pointer;
}

.list-title-container h3{
    margin: 0px 0px 5px 0px;
}

.list-year-container{
    margin: 0px 0px 10px 0px;
}

.list-summary-container p:not(:first-child){
    margin: 0px 0px 0px 5pt;
}