.newsletter-container, .contact-container{
    display: flex;
    flex-direction: column;
    height: 100dvh;
    width: 100dvw;
    z-index: 100;
    position: fixed;
    align-items: center;
    align-content: center;
    justify-content: center;
    visibility: hidden;
}

.contact-container{
    min-height: 100dvh;
    height: auto;
}

.newsletter-container.visible, .contact-container.visible{
    opacity: 1;
    visibility: visible;
}

.newsletter-container.hidden, .contact-container.hidden{
    opacity: 0;
    visibility: hidden;
}

